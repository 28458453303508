<template lang="pug">
    .card-time-ago.d-md-inline-block(:title="listing.created.toDate() | asDate")
      .d-flex.justify-center.justify-md-start
        .text-capitalize {{timeAgo}}
        span.price-warning.ml-1(v-if="listing.newPrice && listing.originalPrice.price !== listing.newPrice.price") it was {{listing.originalPrice.currencySymbol}} {{listing.originalPrice.price | asCurrency}}
</template>

<script>
import ago from "s-ago";
export default {
  name: "CardTimeAgo",
  props: { listing: { type: Object, required: true } },
  computed: {
    timeAgo() {
      return ago(this.listing.created.toDate());
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
.card-time-ago {
  font-weight: bold;

  background: rgb(193, 193, 193, 0.5);
  color: rgb(85, 85, 85);
  padding: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-shadow: 1px 1px rgba(255, 255, 255, 0.2);
  cursor: default;
}
</style>
